import React from "react";
import { Link } from "react-router-dom"; 
import Header from "../components/Header";
import Footer from "../components/Footer";
import abtimg from "../images/abt-img.jpg";

function Treks() {
  const data = [
    {
      name: `Tirthan, Jibhi, Jalori`,
      img: `/trek-imgs/jibhi.png`,
      description: ` Immerse yourself in the tranquil beauty of Jibhi's pristine landscapes, unwind by the serene waters of the Tirthan River, and conquer the breathtaking heights of Jalori Pass for panoramic views.`,
      url: "/trekone" ,
    },
    {
      name: `Udaipur, Mount Abu`,
      img: `/trek-imgs/mountabu.jpg`,
      description: `Udaipur, known as the "City of Lakes," captivates with its majestic palaces and vibrant culture. Mount Abu, Rajasthan's only hill station, offers a serene retreat with scenic landscapes and cool climate.`,
      url: "/Trek2"
    },
    {
      name: `Kasol, Kheerganga`,
      img: `/trek-imgs/kheerganga.jpg`,
      description: `The Kasol to Kheerganga trek in Parvati Valley provides stunning Himalayan views, pine forests, hot springs, and spiritual vibes, drawing in adventurers and seekers.`,
      url: "/Trek3"
    },
  
   
  ];
  const data1 = [
    {
      name: `Jaisalmer`,
      img: `/trek-imgs/Jaisalmer.jpg`,
      description: `Jaisalmer is known for the majestic Jaisalmer fort that fences the city. The most amazing thing about this fort is that it is a living urban center. The walls of this fort house approximately 3000 residents of Jaisalmer.`,
      url: "/Trek4"
    },
    {
      name: `Manali, Kullu, Kasol`,
      img: `/trek-imgs/manali.png`,
      description: `Nestled in the Himalayas, Manali and Kasol offer a perfect escape into nature's lap. Manali is known for its landscapes and adventure, while Kasol charms with its tranquility by the Parvati River. `,
      url: "/Trek5"
    },
    {
      name: `Chopta, Tungnath`,
      img: `/trek-imgs/tungnath.png`,
      description: `Experience the captivating Tungnath-Chandrashila Trek in the Garhwal Himalayas. Leading to the highest Shiva temple, Tungnath, and culminates at Chandrashila Peak for breathtaking panoramic views.`,
      url: "/Trek6"
    },
  
   
  ];
  const data2 = [
    
    {
      name: `Char Dham Yatra`,
      img: `/trek-imgs/chardham.png`,
      description: `The Char Dham Yatra in India is a sacred pilgrimage to Badrinath, Kedarnath, Gangotri, and Yamunotri, nestled in the majestic Himalayas, cherished for spiritual renewal and divine blessings.`,
      url: "/Trek7"
    },

    {
      name: `Mcleod Ganj`,
      img: `/trek-imgs/mcleod.jpg`,
      description: `McLeod Ganj, nestled in the scenic Kangra district of Himachal Pradesh, India, is a picturesque hill station known for its vibrant Tibetan culture. Often called "Little Lhasa," it is home to the Dalai Lama.`,
      url: "/Trek8"
    },

    {
      name: `Udaipur, Mount Abu`,
      img: `/trek-imgs/mountabu2.png`,
      description: `The "City of Lakes," Udaipur, enthralls with its opulent palaces and lively culture. The only hill station in Rajasthan, Mount Abu, provides a tranquil getaway with beautiful scenery and a mild climate.`,
      url: "/Trek9"
    },
   
  ];
  const data3 = [
    {
      name: `Udaipur, Kumbhalgarh`,
      img: `/trek-imgs/kumbal.jpg`,
      description: `The "City of Lakes," Udaipur, enthralls with its opulent palaces and lively culture. The only hill station in Rajasthan, Mount Abu, provides a tranquil getaway with beautiful scenery and a mild climate.`,
      url: "/Trek10"
    },

    {
      name: `Leh, Nubra, Turtuk, Pangong`,
      img: `/trek-imgs/leh1.jpeg`,
      description: `Leh is a high-desert city in the Indian state of Ladakh, known for its stunning landscapes, rich cultural heritage, and historical significance. Situated at an altitude of approximately 3,500 meters. Nubra Valley, located to the north of Leh, is renowned for its striking beauty and unique landscapes.`,
      url: "/Trek11"
    },

    {
      name: `Leh, Nubra, Pangong`,
      img: `/trek-imgs/leh2.jpg`,
      description: `Leh, the capital of Ladakh, is a high-altitude city known for its stunning Himalayan landscapes, ancient monasteries, and rich Tibetan culture. It's a popular destination for trekking, adventure sports, and exploring historical sites.`,
      url: "/Trek12"
    },
  ]

  return (
    <>
      <Header />
      <div className="treks-conatiner">
        <img
          src={abtimg}
          alt=""
          className="w-full h-[33rem] block brightness-75"
        />
        <div className="overlay-text absolute left-[50%] top-[45%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white text-[65px] font-bold  ">
          All Tours & Treks
        </div>
      </div>
      <div className="head flex justify-center items-center flex-col my-6">
          <div className="heading text-[36px] font-bold flex justify-center items-center">
            All Tours & Treks
          </div>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400 " />{" "}
        </div>
      <div className="all-treks flex flex-col    px-[4rem] my-12 ">
        <div className="section-1 md:flex md:flex-row flex-col gap-[3rem] ">
          {data.map((d) => (
            <div className="bg-white-300 trek-card h-fit w-full p-6 mt-7  text-black rounded-xl flex flex-col justify-center">
              <div className="flex  ">
                <img
                  className="w-[400px] h-[200px] rounded-xl flex  gap-5 justify-center items-center"
                  src={d.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5  ">
                <p className="font-extrabold  ">{d.name}</p>
                <p className="  ">{d.description}</p>
                <div className="   ">
                  {" "}
                  <Link to={d.url}>
                  <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-6 py-2 rounded-xl ">
                    Apply now
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="section-1 md:flex md:flex-row flex-col gap-12 ">
          {data1.map((d) => (
            <div className="bg-white-300 trek-card h-fit w-full p-6 mt-12 mt-7 text-black rounded-xl flex flex-col justify-center">
              <div className="flex  ">
                <img
                  className="w-[400px] h-auto rounded-xl flex  gap-5 justify-center items-center"
                  src={d.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5  ">
                <p className="font-extrabold  ">{d.name}</p>
                <p className="  ">{d.description}</p>
                <div className="   ">
                  {" "}
                  <Link to={d.url}>
                  <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-6 py-2 rounded-xl ">
                    Apply now
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="section-1 md:flex md:flex-row flex-col gap-12 ">
          {data2.map((d) => (
            <div className="bg-white-300  trek-card h-fit w-full p-6 mt-7  text-black rounded-xl flex flex-col justify-center">
              <div className="flex   ">
                <img
                  className="w-[400px] h-[200px] flex  gap-5 justify-center items-center rounded-xl"
                  src={d.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5  ">
                <p className="font-extrabold  ">{d.name}</p>
                <p className="  ">{d.description}</p>
                <div className="   ">
                  {" "}
                  <Link to={d.url}>
                  <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-6 py-2 rounded-xl ">
                    Apply now
                  </button>
                  </Link>
                </div>
              </div>
            </div>
            
            
          ))}
        </div>
        <div className="section-1 md:flex md:flex-row flex-col gap-12 ">
          {data3.map((d) => (
            <div className="bg-white-300 trek-card h-fit w-full p-6 mt-12 mt-7 text-black rounded-xl flex flex-col justify-center">
              <div className="flex  ">
                <img
                  className="w-[400px] h-auto rounded-xl flex  gap-5 justify-center items-center"
                  src={d.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5  ">
                <p className="font-extrabold  ">{d.name}</p>
                <p className="  ">{d.description}</p>
                <div className="   ">
                  {" "}
                  <Link to={d.url}>
                  <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-6 py-2 rounded-xl ">
                    Apply now
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Treks;
