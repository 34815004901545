import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdHeight } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/kheerganga/2.jpg";
import img2 from "../images/trips/kheerganga/3.jpg";
import img3 from "../images/trips/kheerganga/4.jpg";
import img5 from "../images/trips/kheerganga/5.jpg";
import img6 from "../images/trips/kheerganga/6.jpg";
import img7 from "../images/trips/kheerganga/7.jpg";
import img8 from "../images/trips/kheerganga/8.jpg";
import img9 from "../images/trips/kheerganga/9.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trek3() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>4 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdHeight className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">HIGHEST ALTITUDE</h1>
            <p>9,678 ft</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>10 to 60 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">Kheerganga trek is one of the most well-known destinations for trekking and is located at a height of 2950m. Deep in the Parvati Valley, it's considered as nature's paradise as the beauty is almost spellbinding. The Itinerary for the Kheerganga Trek starts with a drive from Delhi to Kasol. Then, the trek starts at Kasol, a small village in Himachal Pradesh's Kullu Manali district. This spot is emerging as a popular tourist location best for budget traveling. You'll get to enjoy one-night camping as staying options at Kasol before we reach Kheerganga trek the next day. All you need are two days to explore the landscape and all the picturesque places. The Kheerganga trek reveals stunningterrains and provides an invigorating journey. Also, don't forget to check out the music festival on a trip to Kasol, they're one ofthemost amazing parts ofthe journey.{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Pickup & Drop:-</h1>
          <p className="p-4">Delhi to Delhi</p>
          <h1 className="text-[36px] font-bold px-4"> Day:- 0</h1>
          <p className="p-4"> <li>Pickup From Delhi</li></p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 KASOL</h1>
          <p className="p-4">
            <li>Reach Kasol by morning.</li>
            <li>After having breakfast and some leisure, you can explore the Kasol market, and try different cuisines at the local Hippie Cafe's or just relax by the gushing Parvati river.</li>
            <li>In the evening, go for a hike to Chalal village and capture some beautiful views of Parvati Valley.</li>
            <li>Chill at the Cafe's in Kasol and return to the Hotel for Bonfire & Sleep overnight.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 TREK TO KHEERGANGA</h1>
          <p className="p-4">
            <li>Wake up early morning and after having breakfast, head for Kheerganga. Trek will start from a village called Barsheni(22 km from Kasol) and you will be transferred to Barsheni in private vehicles.</li>
            <li>The Trek Trail of Kheerganga is 12 kms with a moderate difficulty level. Enjoy your walk in the heart of the beautiful Parvati valley with a stunning view of snowcapped mountains all around.</li>
            <li>Reach Kheerganga by evening.</li>
            <li>Have your dinner at bonfire under the sky studded with stars and snowcapped mountains all around.</li>
            <li>Sleep overnight in the camps.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 TREK DOWN TO BARSHENI</h1>
          <p className="p-4">
            <li>Wake up early morning to see a beautiful sunrise and after having breakfast head back to Kasol.</li>
            <li>Trek your way down to Barsheni and reach Kasol by evening.</li>
            <li>On the way, visit the holy Manikaran Gurudwara, famous for it's natural hot springs.</li>
            <li>Head back to Delhi with lots of amazing trip memories. </li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 SWEET HOME</h1>
          <p className="p-4">
            <li>Reach Delhi in early morning.</li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹6500 <span className="">+ 5% GST (Quad Sharing) <br></br>₹7000 + 5% GST (Triple Sharing) <br></br> ₹7500 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img9} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">AC Traveller</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (Breakfast & Dinner)</h2>
            </li>
            <li>
              <h2 className="font-semibold">Cottage & Hotel</h2>
            </li>
            <li>
              <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
            <li>
              <h2 className="font-semibold">All travel permits</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">All tickets and entry pass</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trek3;
