import React, { useState } from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";

import "../css/home.css";
import bg6 from "../images/bg6.png";
import abt from "../images/abt.jpg";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa"; // Import arrow icons
import Slider from "react-slick";
import { CiCirclePlus } from "react-icons/ci";
import { GoShieldLock } from "react-icons/go";
import { TbArrowGuide } from "react-icons/tb";
import { GrRestroomWomen } from "react-icons/gr";
import { FaHandshake } from "react-icons/fa";
import { FaChildren } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { Link } from "react-router-dom";

function Home() {
  
  const data = [
    {
      name: `Tirthan, Jibhi, Jalori`,
      img: `/trek-imgs/jibhi.png`,
      description: ` Immerse yourself in the tranquil beauty of Jibhi's pristine landscapes, unwind by the serene waters of the Tirthan River, and conquer the breathtaking heights of Jalori Pass for panoramic views.`,
      url: "/trekone" ,
    },
    {
      name: `Udaipur, Mount Abu`,
      img: `/trek-imgs/mountabu.jpg`,
      description: `Udaipur, known as the "City of Lakes," captivates with its majestic palaces and vibrant culture. Mount Abu, Rajasthan's only hill station, offers a serene retreat with scenic landscapes and cool climate.`,
      url: "/Trek2"
    },
    {
      name: `Jaisalmer`,
      img: `/trek-imgs/Jaisalmer.jpg`,
      description: `Jaisalmer is known for the majestic Jaisalmer fort that fences the city. The most amazing thing about this fort is that it is a living urban center. The walls of this fort house approximately 3000 residents of Jaisalmer.`,
      url: "/Trek4"
    },
    {
      name: `Manali, Kullu, Kasol`,
      img: `/trek-imgs/manali.png`,
      description: `Nestled in the Himalayas, Manali and Kasol offer a perfect escape into nature's lap. Manali is known for its landscapes and adventure, while Kasol charms with its tranquility by the Parvati River. `,
      url: "/Trek5"
    },
  ];
  const [currentSlide, setCurrentSlide] = useState(0);

  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };

  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slick-arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };
  const settings = {
    dots: true,
    arrows: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
  };
  const settings2 = {
    dots: true,
    arrows: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    beforeChange: (current, next) => setCurrentSlide(next),
  };
  const review = [
    {
      img: `/review_imgs/r1.jpg`,
      name: `Roshan Singh`,
      designation: `Software engineer`,
      review: `It was really felt great travelling the mountains withis amazing team.`,
    },
    {
      img: `/review_imgs/r2.jpg`,
      name: `Sakshi Tappad`,
      designation: `Geologist`,
      review: `It was really felt great travelling the mountains withis amazing team.`,
    },
    {
      img: `/review_imgs/r3.jpeg`,
      name: `Khushi Agarwal`,
      designation: `Model`,
      review: `It was really felt great travelling the mountains withis amazing team.`,
    },
    {
      img: `/review_imgs/r4.jpg`,
      name: `Harsh Rathore`,
      designation: `Entreprenuer`,
      review: `It was really felt great travelling the mountains withis amazing team.`,
    },
  ];
  const qna = [
    {
      question: `Why is your trek fee higher than other trekking companies?`,
      answer: `Our trek fee is higher due to our commitment to providing top-notch service, experienced guides, sustainable practices, small group sizes, and comprehensive safety measures.`,
    },
    {
      question: `I'm a solo woman. Is it safe for me?`,
      answer: `Absolutely, our priority is ensuring the safety and comfort of all our clients, including solo women, with experienced guides, strict safety protocols, and a supportive environment throughout the trek.`,
    },
    {
      question: `What are some easy treks for beginners?`,
      answer: `Some easy treks for beginners in India include the Valley of Flowers trek in Uttarakhand, the Triund trek in Himachal Pradesh, and the Kheerganga trek in Himachal Pradesh.`,
    },
    {
      question: `Why are you so strict about fitness approvals?`,
      answer: `We prioritize safety and ensure that all participants are physically prepared for the trek to minimize the risk of injuries or health complications during the journey.`,
    },
    {
      question: `I have health issues like BP, asthma, diabetes. can I trek?`,
      answer: `We initialize an array answersVisibility to track the visibility state of each answer. Each element corresponds to an FAQ item, initialized to false indicating that the answer is initially hidden.`,
    },
    {
      question: `Can I join your groups as a solo trekker?`,
      answer: `Absolutely, we welcome solo trekkers to join our groups, providing an opportunity to meet new people and share memorable experiences while exploring the great outdoors.`,
    },
    {
      question: `Why do I have to register so much in advance?`,
      answer: `
      ChatGPT
      Registering in advance allows us to adequately prepare for your trek, including arranging permits, accommodations, transportation, and ensuring that we can accommodate any special requests or requirements you may have, ensuring a smooth and enjoyable trekking experience., initialized to false indicating that the answer is initially hidden.`,
    },
    {
      question: `I'm obese and my BMI is high. Can I trek with you?`,
      answer: `
      ChatGPT
      Certainly! We encourage individuals of all fitness levels to participate in our treks. However, it's important to consider the physical demands of trekking and consult with a healthcare professional to ensure it's suitable for your health condition. We can also provide personalized guidance and support to make your trekking experience safe and enjoyable.`,
    },
    {
      question: `Do you have any group discounts?`,
      answer: `
      ChatGPT
      Yes, we offer group discounts for parties of a certain size, providing an incentive for friends, families, or colleagues to embark on a memorable trekking adventure together.`,
    },
    {
      question: `What are toilets like? I'm worried!`,
      answer: `Rest assured, we prioritize your comfort and hygiene during treks, providing various toilet facilities ranging from basic pit toilets to more modern options at designated camping sites or along the trekking route.`,
    },
  ];
  const advantages = [
    {
      title: `Expert Guides`,
      icon: <GoShieldLock />,
      para: `Benefit from experienced guides who enhance your journey with their in-depth knowledge of local culture, history, and nature, ensuring an enriching and insightful trekking experience.`,
    },
    {
      title: `Personalized Service`,
      icon: <TbArrowGuide />,
      para: `Enjoy personalized attention and support throughout your trek, from customized itineraries to tailored assistance for individual needs or preferences, ensuring a memorable and hassle-free adventure.`,
    },
    {
      title: `Safety Assurance `,
      icon: <GrRestroomWomen />,
      para: `Trek with confidence knowing that safety is our top priority, with comprehensive safety protocols, emergency procedures, and trained staff to ensure your well-being throughout the journey.`,
    },
  ];
  const advantages2 = [
    {
      title: `Meet like-minded trekkers

  `,
      icon: <FaHandshake />,
      para: `Indiahikes carries with it a strong spirit of trekking, one that comes with fitness, minimalism, mindfulness and a deep love for nature. `,
    },
    {
      title: `Avail a 30% scholarship for children aged 8-14`,
      icon: <FaChildren />,
      para: `We want to see more children trekking. Indiahikes will sponsor 30% of your child’s trek fee. Let them explore nature, build confidence, and create memories.`,
    },
    {
      title: `You’re guarded with our trek again philosophy`,
      icon: <TbTrekking />,
      para: `Sign up for an Indiahikes trek with 10 or more trekkers, and one lucky member gets their trek fee waived entirely! You only pay for 9. `,
    },
  ];
  // State to track the visibility of answers
  const [answersVisibility, setAnswersVisibility] = useState(
    Array(qna.length).fill(false)
  );

  // Function to toggle the visibility of an answer
  const toggleAnswerVisibility = (index) => {
    const updatedVisibility = [...answersVisibility];
    updatedVisibility[index] = !updatedVisibility[index];
    setAnswersVisibility(updatedVisibility);
  };
  return (
    <div>
      <Header className="z-10" />
      <div className="relative h-screen ">
        <img
          src={bg6}
          alt=""
          className="absolute inset-0 w-full h-full object-cover brightness-50"
        />
        <div className="home-te absolute inset-0 flex px-4 md:px-16 flex-col items-start justify-start mt-32 md:mt-60">
          <h1 className=" text-5xl text-white font-bold">
          Destinations On Demand: Where Your Journey Begins
          </h1>
          <Link to="/treks">
          <button className="text-black w-[16rem] p-2 mt-12 font-semibold rounded-[8px] bg-custom-yellow">
            See all upsoming treks
          </button>
          </Link>
          <p className="text-white font-semibold mt-12 text-[18px] ">
          Set off on a journey beyond boundaries, where each destination becomes an unforgettable adventure.
          </p>
          <button className="text-black font-semibold mt-8">
          We provide tailored journeys for the ultimate adventure.

          </button>
        </div>
      </div>
      <div className="about-section flex flex-col items-center mt-12">
        <h1 className="text-[26px] font-bold px-4 md:px-0">
        Why is traveling with Destinations On Demand different?
        </h1>
        <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        <div className="abt flex flex-col mt-24 py-12 md:flex md:flex-row px-12 items-center gap-10">
          <img src={abt} alt="" />
          <div className="abt-te">
            <p>
              <span className="text-[26px] font-bold">Do</span> you ever go through this? You're locked in a position that doesn't give you time to explore. Your everyday routine lacks excitement, which makes you restless. You want something more meaningful. This is where our journeys make a difference. Our outdoor experts will guide lessons that incorporate mindfulness throughout your adventure. They participate in thought-provoking exercises amid the mountains, allowing you to ponder and contemplate. The person you are before and after a journey is rarely the same. Our guides teach you new outdoor skills that will give you the confidence to go alone anywhere in the globe. All of this takes place among the most breathtaking alpine landscape seen in the Indian Himalayas.
            </p>
            <p className="font-bold">Akshay Mittal, CEO, Destinations On Demand</p>
          </div>
        </div>
      </div>
      <div className="trekers-carousel w-[22rem] md:w-[1340px] m-auto mt-[20px]">
        <div className="head flex justify-center items-center flex-col my-6">
          <div className="heading text-[36px] font-bold flex justify-center items-center">
            All treks
          </div>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400 " />{" "}
        </div>
        <div className="mt-10 h-full hidden md:block">
          <Slider {...settings}>
          {data.map((d) => (
            <div className="bg-white-300 trek-card h-fit my-4 w-full p-6  text-black rounded-xl flex-1 flex-col justify-center">
              <div className="flex  ">
                <img
                  className="w-[400px] h-[200px] rounded-xl flex  gap-5 justify-center items-center"
                  src={d.img}
                  alt=""
                />
              </div>
              <div className="flex flex-col gap-5  ">
                <p className="font-extrabold  text-[22px] mt-2">{d.name}</p>
                <p className="  ">{d.description}</p>
                <div className="   ">
                  {" "}
                  <Link to={d}>
                  <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-4 py-2 rounded-xl ">
                    Apply now
                  </button>
                  </Link>
                </div>
              </div>
            </div>
          ))}
          
          </Slider>
        </div>
        <div id="slider-container" className="relative mt-20 block md:hidden">
  <Slider {...settings2}>
    {data.map((d, index) => (
      <div key={index} className="card bg-gray-300 my-4 trek-card h-auto p-6 text-black rounded-xl flex flex-col justify-between">
        <div className="flex justify-center items-center flex-grow">
          <img
            className="max-w-full max-h-[200px] object-contain"
            src={d.img}
            alt={d.name}
          />
        </div>
        <div className="flex flex-col gap-2 mt-2 flex-grow">
          <p className="font-extrabold text-[22px]">{d.name}</p>
          <p>{d.description}</p>
        </div>
        <div className="flex justify-center mt-4">
          <button className="w-[10rem] font-semibold bg-yellow-500 py-2 rounded-xl">
            Apply now
          </button>
        </div>
      </div>
    ))}
  </Slider>
</div>

      </div>
      
      <div className="qna flex flex-col items-center justify-center mt-36 mb:px-0rem px-7">
        <h1 className="text-[36px] font-semibold">
          Frequently asked questions
        </h1>
        <hr className="w-full md:w-[40rem] mt-3 mb-10 h-1 bg-yellow-400" />
        <div className="qna-card flex items-center justify-center gap-2 ">
          <div>
            {qna.map((q, index) => (
              <div key={index}>
                <div className="w-[100%] mb:w-[40rem] p-3 border-2 border-gray-200 mb:mx-0 px-3">
                  <p className="flex items-center font-semibold justify-between gap-0 mb:gap-36  ">
                    {q.question}{" "}
                    <CiCirclePlus
                      className="text-yellow-600 text-[18px] cursor-pointer"
                      onClick={() => toggleAnswerVisibility(index)}
                    />
                  </p>
                </div>
                {/* Answer */}
                {answersVisibility[index] && (
                  <p className="mt-2 w-full md:w-[40rem] p-3 bg-black text-white">
                    {q.answer}
                  </p>
                )}
              </div>
            ))}
          </div>
          <div></div>
        </div>
      </div>
      <div className="advantages mx-10 mt-28 flex flex-col items-center justify-center">
        <h1 className="text-[36px] font-semibold">The Destinations On Demand Advantage</h1>
        <hr className="w-full md:w-[40rem] mt-3 mb-10 h-1 bg-yellow-400" />
        <div className="adv-card flex md:flex md:flex-row flex-col gap-10 ">
          <div>
            {advantages.map((ad, index) => (
              <div
                key={index}
                className="p-7 ... ring-2 ring-gray-200 ring-inset mb-4"
              >
                <div className="flex items-center justify-between">
                  {" "}
                  <h1 className="text-[20px] font-semibold">{ad.title}</h1>
                  <div className="text-yellow-600 text-[36px]">{ad.icon}</div>
                </div>
                <p>{ad.para}</p>{" "}
              </div>
            ))}
          </div>
          <div>
            {advantages.map((ad, index) => (
              <div
                key={index}
                className="p-7 ... ring-2 ring-gray-200 ring-inset mb-4"
              >
                <div className="flex items-center justify-between">
                  {" "}
                  <h1 className="text-[20px] font-semibold">{ad.title}</h1>
                  <div className="text-yellow-600 text-[36px]">{ad.icon}</div>
                </div>
                <p>{ad.para}</p>{" "}
              </div>
            ))}
          </div>
        </div>
      </div>
      <Footer />{" "}
    </div>
  );
}

export default Home;
