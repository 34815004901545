import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/leh1/1.jpeg";
import img2 from "../images/trips/leh1/2.jpeg";
import img3 from "../images/trips/leh1/3.jpeg";
import img5 from "../images/trips/leh1/4.jpeg";
import img6 from "../images/trips/leh1/5.jpeg";
import img7 from "../images/trips/leh1/6.jpeg";
import img8 from "../images/trips/leh1/7.jpeg";
import img9 from "../images/trips/leh1/8.jpeg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trek11() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>7 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>10 to 60 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">
          Situated right on the border on the north west edge of Ladakh is Turtuk which was once a part of Pakistan till the 1971 Indopak war to be specific. With this 7 days tour to Ladakh, enjoy a journey to the top of the world featuring most of the world's highest motorable passes with the spectacular beauty of snow-capped mountains. Admired for its unparalleled scenic views, pristine icy lakes, and swirling roads, Ladakh is becoming the most Instagrammable place in India and is on everyone's bucket list.{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Pickup & Drop:-</h1>
          <p className="p-4">Leh to Leh</p>
          <h1 className="text-[36px] font-bold px-4"> Day:- 0 JOIN US IN LEH</h1>
          <p className="p-4">
            <li>Early Morning Arrival.</li>
            <li>Receive by our representative at the Leh airport IXL and get transferred to the hotel. </li>
            <li>Welcome tea and coffee serve.</li>
            <li>Complete day of rest for acclimatization.</li>
            <li>Overnight in hotel at Leh.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 LEH - SANGAM - LEH</h1>
          <p className="p-4">
            <li>After breakfast go for the Indus river visiting, Pathar Sahib Gurdwara and Magnetic Hill where the cars defy gravity,hall of fame. </li>
            <li>Late afternoon visit Leh Palace, Shanti Stupa and Leh Market.</li>
            <li>After that come back to Leh and stay the night.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 LEH - NUBRA VALLEY</h1>
          <p className="p-4">
            <li>After an early breakfast drive to Nubra Valley via Khardungla pass(approx 130 kms), the highest motorable road in the world at a height of about 18,390, around 39km from Leh.</li>
            <li>At the top spectacular views of Himalaya and Karakoram Range.</li>
            <li>After reach at Nubra valley first we will visit Diskit monastery there we can see the magnificent 104ft openstatue of Future Buddha. Later visit ATB Point.</li>
            <li>Afternoon visit Hundar village and can enjoy double hump camel ride on sand dunes. Overnight stay at camp and enjoy the beautiful sky at night.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 NUBRA - TURTUK </h1>
          <p className="p-4">
            <li>After a delicious breakfast, drive towards Turtuk. </li>
            <li> Turtuk is the last outpost in India after which the Pakistan controlled Gilgit-Baltistan begins and is one of the gateways to the Siachen Glacier.</li>
            <li>The place is culturally, linguistically, and historically different from what you've seen till now in your Ladakh holiday as it falls under the Gilgit-Baltistan region.</li>
            <li>Even the landscape is different with dramatic narrow valleys and steep mountains, unlike Central Ladakh land Nubra.</li>
            <li>Comparatively, situated at a lower altitude than Leh, you'll see green pastures here that yield fresh produce like apricots and apples. Overnight stay in Nubra camps.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 NUBRA - PANGONG</h1>
          <p className="p-4">
            <li>After having breakfast drive to beautiful Pangong lake via Shayok valley.</li>
            <li>Spend some time at the lake and then drive back to Overnight stay at Pangong.</li>
            <li>Overnight at Camp in Pangong.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 5 PANGONG - LEH</h1>
          <p className="p-4">
            <li>After having breakfast, drive back to Leh via Changla pass the 3rd highest motorable road in the world.</li>
            <li>Your next destination is Rancho's School.</li>
            <li>On way back visit one of the beautiful Thiksay monastery.</li>
            <li>Overnight at Hotel in Leh.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 6 LEH DEPARTURE</h1>
          <p className="p-4">
            <li>It's Time to say Goodbye to the Magical Land, Early morning transfer to the airport and fly back to Delhi with sweet memories of Ladakh.</li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹20000 <span className="">+ 5% GST (Quad Sharing) <br></br>₹22000 + 5% GST (Triple Sharing) <br></br> ₹25000 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img9} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">AC Traveller</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (Breakfast & Dinner)</h2>
            </li>
            <li>
              <h2 className="font-semibold">Cottage & Hotel</h2>
            </li>
            <li>
              <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
            <li>
              <h2 className="font-semibold">All travel permits</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">All tickets and entry pass</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trek11;
