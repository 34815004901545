import React, { useState } from "react";
import abt from "../images/abt.jpg";
import bg8 from "../images/bg8.png"
import Header from '../components/Header';
import Footer from '../components/Footer';
import { CiCirclePlus } from "react-icons/ci";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
function About() {
  const qna = [
    {
      question: `Why is your trek fee higher than other trekking companies?`,
      answer: `Our trek fee is higher due to our commitment to providing top-notch service, experienced guides, sustainable practices, small group sizes, and comprehensive safety measures.`,
    },
    {
      question: `I'm a solo woman. Is it safe for me?`,
      answer: `Absolutely, our priority is ensuring the safety and comfort of all our clients, including solo women, with experienced guides, strict safety protocols, and a supportive environment throughout the trek.`,
    },
    {
      question: `What are some easy treks for beginners?`,
      answer: `Some easy treks for beginners in India include the Valley of Flowers trek in Uttarakhand, the Triund trek in Himachal Pradesh, and the Kheerganga trek in Himachal Pradesh.`,
    },
    {
      question: `Why are you so strict about fitness approvals?`,
      answer: `We prioritize safety and ensure that all participants are physically prepared for the trek to minimize the risk of injuries or health complications during the journey.`,
    },
    {
      question: `Can I join your groups as a solo trekker?`,
      answer: `Absolutely, we welcome solo trekkers to join our groups, providing an opportunity to meet new people and share memorable experiences while exploring the great outdoors.`,
    },
    {
      question: `Why do I have to register so much in advance?`,
      answer: `
      ChatGPT
      Registering in advance allows us to adequately prepare for your trek, including arranging permits, accommodations, transportation, and ensuring that we can accommodate any special requests or requirements you may have, ensuring a smooth and enjoyable trekking experience., initialized to false indicating that the answer is initially hidden.`,
    },
    {
      question: `I'm obese and my BMI is high. Can I trek with you?`,
      answer: `
      ChatGPT
      Certainly! We encourage individuals of all fitness levels to participate in our treks. However, it's important to consider the physical demands of trekking and consult with a healthcare professional to ensure it's suitable for your health condition. We can also provide personalized guidance and support to make your trekking experience safe and enjoyable.`,
    },
    {
      question: `Do you have any group discounts?`,
      answer: `
      ChatGPT
      Yes, we offer group discounts for parties of a certain size, providing an incentive for friends, families, or colleagues to embark on a memorable trekking adventure together.`,
    },
    {
      question: `What are toilets like? I'm worried!`,
      answer: `Rest assured, we prioritize your comfort and hygiene during treks, providing various toilet facilities ranging from basic pit toilets to more modern options at designated camping sites or along the trekking route.`,
    },
  ];
       // State to track the visibility of answers
   const [answersVisibility, setAnswersVisibility] = useState(Array(qna.length).fill(false));

   // Function to toggle the visibility of an answer
   const toggleAnswerVisibility = (index) => {
     const updatedVisibility = [...answersVisibility];
     updatedVisibility[index] = !updatedVisibility[index];
     setAnswersVisibility(updatedVisibility);
   };
  return (
    <>
    <Header/>
    <div className="abt-img">
<img src={bg8} alt="" className="w-full h-[33rem] block brightness-75" />
<div className="overlay-text absolute top-[30%] left-[40%] md:left-[50%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white text-[65px] font-bold  ">About Treks</div>
<div className="icons flex items-center justify-center p-8 bg-custom-yellow gap-4">
                <FaInstagram  />
                <FaFacebookF  />
                <FaYoutube  />
                <FaTwitter  />
              </div>
    </div>
    <div className="abt-container">

    <div className="about-section flex flex-col mt-12 px-[] md:px-14  items-center">
        <h1 className="text-[26px] font-bold md:px-[] p-4">
        Why is traveling with Destinations On Demand different?
        </h1>
        <hr className="w-full  md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        <div className="abt flex flex-col mt-24 py-12 md:flex md:flex-row px-8 items-center gap-10">
          <img src={abt} alt="" />
          <div className="abt-te">
            <p>
              <span className="text-[26px] font-bold">Do</span> you ever go through this? You're locked in a position that doesn't give you time to explore. Your everyday routine lacks excitement, which makes you restless. You want something more meaningful. This is where our journeys make a difference. Our outdoor experts will guide lessons that incorporate mindfulness throughout your adventure. They participate in thought-provoking exercises amid the mountains, allowing you to ponder and contemplate. The person you are before and after a journey is rarely the same. Our guides teach you new outdoor skills that will give you the confidence to go alone anywhere in the globe. All of this takes place among the most breathtaking alpine landscape seen in the Indian Himalayas.
            </p>
            <p className="font-bold">Akshay Mittal, CEO, Destinations On Demand</p>
          </div>
        </div>
      </div>
      <div className="qna flex flex-col items-center justify-center mt-36 mb-8 md:px-0rem px-7">
        <h1 className="text-[36px] font-semibold">
          Frequently asked questions
        </h1>
        <hr className="w-full  md:[40rem] mt-3 mb-10 h-1 bg-yellow-400" />
        <div className="qna-card flex items-center justify-center gap-2 ">
          <div>
            {qna.map((q,index) => (
              <div
              key={index}>
                <div className="w-[100%] mb:w-[40rem] p-3 border-2 border-gray-200 mb:mx-0 px-3">
              <p className="flex items-center font-semibold justify-between gap-0 mb:gap-36  ">
                {q.question}{" "}
                <CiCirclePlus className="text-yellow-600 text-[18px] cursor-pointer" onClick={() => toggleAnswerVisibility(index)}/>
                </p>  
                 </div>
                 {/* Answer */}
                 {answersVisibility[index] && <p className="mt-2 w-full md:w-[40rem] p-3 bg-black text-white">{q.answer}</p>}
              </div>
            ))}
          </div>
          <div>
      
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    </>
  )
}

export default About