import Home from './pages/Home';
import Contact from './pages/Contact';
import Apply from './pages/Apply';
import Treks from './pages/Treks';
import {
  Routes,
  BrowserRouter,
  Route,
  useLocation,
} from "react-router-dom"
import './App.css';
import React, { useState } from "react";
import { useEffect } from "react";
import About from './pages/About';
import Trekone from './pages/Trekone';
import Trek2 from './pages/Trek2'
import Trek3 from './pages/Trek3'
import Trek4 from './pages/Trek4'
import Trek5 from './pages/Trek5'
import Trek6 from './pages/Trek6'
import Trek7 from './pages/Trek7'
import Trek8 from './pages/Trek8'
import Trek9 from './pages/Trek9'
import Trek10 from './pages/Trek10';
import Trek11 from './pages/Trek11';
import Trek12 from './pages/Trek12';
const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
  <>
  <BrowserRouter>
  <ScrollToTop/>
  <Routes>
<Route index element={<Home />} />
<Route path="/home" element={<Home />} />
<Route path="/contact" element={<Contact />} />
<Route path="/about" element={<About />} />
<Route path="/treks" element={<Treks />} />
<Route path="/trekone" element={<Trekone />} />
<Route path="/Trek2" element={<Trek2/>}/>
<Route path="/Trek3" element={<Trek3/>}/>
<Route path="/Trek4" element={<Trek4/>}/>
<Route path="/Trek5" element={<Trek5/>}/>
<Route path="/Trek6" element={<Trek6/>}/>
<Route path="/Trek7" element={<Trek7/>}/>
<Route path="/Trek8" element={<Trek8/>}/>
<Route path="/Trek9" element={<Trek9/>}/>
<Route path="/Trek10" element={<Trek10/>}/>
<Route path="/Trek11" element={<Trek11/>}/>
<Route path="/Trek12" element={<Trek12/>}/>
<Route path="/Applyone" element={<Apply />} />
</Routes>
</BrowserRouter>
</>
);
}
export default App;
