import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdHeight } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/chardham/1.jpg";
import img2 from "../images/trips/chardham/2.jpg";
import img3 from "../images/trips/chardham/3.jpg";
import img5 from "../images/trips/chardham/4.jpg";
import img6 from "../images/trips/chardham/5.jpg";
import img7 from "../images/trips/chardham/6.jpg";
import img8 from "../images/trips/chardham/7.jpg";
import img9 from "../images/trips/chardham/8.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trek7() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>12 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>10 to 60 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">
          Uttarakhand, also known as Devbhoomi orthe Land of Gods, is home to numerous temples and welcomes devotees all year round. Among the countless religious sites and circuits that devotees visit in Uttarakhand, one of the most prominent isthe Char Dham Yatra. This Yatra or pilgrimage is a tour of four holy sites - Yamunotri, Gangotri, Kedarnath and Badrinath - nestled high up in the Himalayas. In Hindi, 'char' means four and 'dham' refers to religious destinations.{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Pickup & Drop:-</h1>
          <p className="p-4">Delhi to Delhi</p>
          <h1 className="text-[36px] font-bold px-4"> Day:- 0</h1>
          <p className="p-4"> <li>Pickup From Delhi</li></p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 REACH HARIDWAR</h1>
          <p className="p-4">
            <li>Reach Haridwar in morning chcek into hotel & freshen up.</li>
            <li>Visit Mansa Devi Temple and Chandi Devi at your own cost. </li>
            <li>In the Evening visit for Ganga Arti at Har Ki Pauri.</li>
            <li>Return back to hotel.</li>
            <li>Dinner overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 BARKOT</h1>
          <p className="p-4">
            <li>Wake up in morning freshen up & have breakfast & then check out from hotel.</li>
            <li>Later depart for Barkot.</li>
            <li>Reach Barkot by evening.</li>
            <li>Check into hotel.</li>
            <li>Dinner & overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 BARKOT / KHARSALI - YAMUNOTRI - BARKO</h1>
          <p className="p-4">
            <li> Wake up early morning & have breakfast, get transferred to Hanumanchatti (40 Km), Janki Chatti (8 km).</li>
            <li>Here you will begin the First Pahad Yatra of Yamunotri (5 Km trek). You can hire a Doli or a horse, for your trek (Cost Not Included). At Yamunotri, One can cook rice by packing it in a cloth and dipping it in the hot water of the hot kund. Pilgrims take this cooked rice home as "Prasad".</li>
            <li>Here near the temple "Pooja" can be offered to Divya Shila, After taking bath in Jamunabai Kund's warn water and having "Darshan" of pious "Yamunaji" returning to Hanumanchatti.</li>
            <li>Later drive back to Barkot.</li>
            <li>Dinner & Overnight stay at Hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 UTTRAKASHI</h1>
          <p className="p-4">
            <li>Post breakfast check out from the Barkot hotel and drive to Uttarkashi.</li>
            <li>On arrival check in into the hotel. Uttarkashi is situated on the banks of river Bhagirathi and is famous for its historical monuments, Temples & Ashrams.</li>
            <li>In the evening, Visit the famous Vishwanath Temple.</li>
            <li>Dinner and Overnight stay at Uttarkashi.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 5 UTTRAKASHI - GANGOTRI - UTTRAKASHI</h1>
          <p className="p-4">
            <li>Early morning breakfast at hotel and drive to Gangotri. Nestled in the magnificent Garhwal Himalayas, Gangotri is set at an altitude of 3048 mts. Upon arrival at Gangotri take a holy dip in the sacred river Ganges which is also called Bhagirathi at its origin Visit the Gangotri Temple. The 18th century's temple dedicated to Goddess Ganga is located near a sacred stone where King Bhagirathi worshipped Lord Shiva. Ganga is believed to have touched earth at this spot.</li>
            <li>After performing Pooja Late afternoon drive back to Uttarkashi.</li>
            <li>Dinner & Overnight stay at Uttarkashi.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 6 UTTRAKASHI - GUPTKASHI / SITAPUR</h1>
          <p className="p-4">
            <li>After early morning breakfast check out from the hotel and drive for Guptkashi/Rampur/Sitapur.</li>
            <li>On arrival check in at hotel. Rest of the day free to explore the Guptkashi town. </li>
            <li>Dinner and Overnight stay in Guptkashi/Rampur/Sitapur hotel/Camps.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 7 GUPTKASHI - RAMPUR - KEDARNATH</h1>
          <p className="p-4">
            <li>Wake up in morning freshen up have breakfast check out from hotel.</li>
            <li>Later depart for Sonprayag/Sitapur, Our vehicle will drop you at Sitapur parking because other state vehicles are not allowed in Sonprayag parking. </li>
            <li>Sitapur to Sonprayag we will cover the distance by walk, it is around 700m.</li>
            <li>From sonprayag we will take a taxi it is  government taxi & it's rate is around 50-60/- per person, Taxi will drop you at Gaurikund.</li>
            <li>At Gaurikund we will start the trek to Kedarnath Temple it is around 24km trek distance, which will almost take 7-8 hours</li>
            <li>Reach Kedarnath temple & Check into the hotel (basic stay dharmshala) & Enjoy the arti& the timing is 6:00-7:15 pm.</li>
            <li>Dinner overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 8 KEDARNATH - GUPTKASHI / RAMPUR</h1>
          <p className="p-4">
            <li>Have breakfast visit & Bhirav temple, it's 2km trek distance from Kedarnath temple.</li>
            <li>Check out from hotel & Later trek down to Gaurikund.</li>
            <li>Reach Gaurikund & Take a taxi for Sonprayag. </li>
            <li>Reach Sonprayag.</li>
            <li>Come to Sitapur parking by walk/taxi it's depend on your strength.</li>
            <li>Later depart for Rampur / Guptkashi.</li>
            <li>Check into hotel, do dinner & overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 9 RAMPUR / GUPTKASHI - PANDUKESHWAR</h1>
          <p className="p-4">
            <li>Wake up in the morning freshen up & have breakfast after that check out from hotel.</li>
            <li>Later depart for Pandukeshwar.</li>
            <li>After driving through some wonderful Ghat road.</li>
            <li>Do lunch at your own cost.</li>
            <li>Arrive Pandukeshwar.</li>
            <li>Check into the hotel.</li>
            <li>Dinner & overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 10 PANDUKESHWAR - BADRINATH - MANA</h1>
          <p className="p-4">
            <li>Wake up in morning freshen up have breakfast check out from hotel.</li>
            <li>Later depart for Badrinath.</li>
            <li>Reach Badrinath do darshan & then visit Tapt Kund.</li>
            <li>Later depart for Mana Village.</li>
            <li>Visit (Ganesh Gufa, Vyass Gufa, Saraswati River, Bhim Pul).</li>
            <li>Later depart for Rudraprayag.</li>
            <li>Dinner & overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 11 DEVPRYAG - RISHIKESH </h1>
          <p className="p-4">
            <li>Wake up in morning freshen up & have breakfast after that check out from hotel.</li>
            <li>Later depart for Rishikesh.</li>
            <li>Visit Devprayag.</li>
            <li>Confluence of Alaknanda, Bhagirath and Ganga river.</li>
            <li>Reach Rishikesh.</li>
            <li>Enjoy Ganga Arti.</li>
            <li>Check into hotel/camps.</li>
            <li>Dinner overnight stay in hotel/camps.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 12 RISHIKESH - DELHI</h1>
          <p className="p-4">
            <li>Freshen up have breakfast check out from hotel.</li>
            <li>Later visit Laxman jhula, Ram jhula, Triveni ghat, etc at your own cost.</li>
            <li>Do Rafting and other adventure activities at your own cost.</li>
            <li>Later depart for Delhi by afternoon.</li>
            <li>Reach Delhi by evening or night.</li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹23500 <span className="">+ 5% GST (Quad Sharing) <br></br>₹25500 + 5% GST (Triple Sharing) <br></br> ₹28500 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img9} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">AC Traveller</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (Breakfast & Dinner)</h2>
            </li>
            <li>
              <h2 className="font-semibold">Cottage & Hotel</h2>
            </li>
            <li>
              <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
            <li>
              <h2 className="font-semibold">All travel permits</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">All tickets and entry pass</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trek7;
