import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdHeight } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/Jibhi/1.jpg";
import img2 from "../images/trips/Jibhi/10.jpg";
import img3 from "../images/trips/Jibhi/3.jpg";
import img5 from "../images/trips/Jibhi/5.jpg";
import img6 from "../images/trips/Jibhi/6.jpg";
import img7 from "../images/trips/Jibhi/8.jpg";
import img8 from "../images/trips/Jibhi/9.jpg";
import img9 from "../images/trips/Jibhi/4.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trekone() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>5 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>1 to 100 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">
           Located in the Kullu district of Himachal Pradesh,
           Tirthan Valley derives its name from the Tirthan river, which winds its way through it. The pristine river originates from the icy cold glacial springs of Hanskund, a snow-capped peak in the verdant Great Himalayan National Park (GHNP). Perched up above 1600 meters of sea level is the retreat of Tirthan Valley. Located at a perfect spot in the Kullu district of Himachal Pradesh, the valley is known for its pristine Tirthan river, icy glacial springs and verdant views at the Great Himalayan National Park.{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Pickup & Drop:-</h1>
          <p className="p-4">Delhi to Delhi</p>
          <h1 className="text-[36px] font-bold px-4"> Day:- 0</h1>
          <p className="p-4"> <li>Pickup From Delhi</li></p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 JIBHI</h1>
          <p className="p-4">
            <li>Reach Jibhi in afternoon check into the cottage freshen up have lunch later visit Jibhi waterfall & Mini Thailand. Return back to cottage.</li>
            <li>Enjoy the bornfire and music night. Dinner overnight stay in cottage.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 JALORI PASS</h1>
          <p className="p-4">
            <li>Wakeup in morning freshen up have breakfast and later depart for Jalori Pass. Reach Jalori Pass and proceed for Serolsar Lake trek. It is around 4-5 km trek. It is a beautifull lake and you can see 360° view.</li>
            <li>Visit Nag Temple.</li>
            <li>Return trek down to Jalori Pass. Return back to cottage.</li>
            <li>Enjoy the bornfire and music night.</li>
            <li>Dinner overnight stay in cottage.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 TIRTHAN VALLEY</h1>
          <p className="p-4">
            <li>Wake up in morning freshen up have breakfast and checkout from cottage.</li>
            <li>Later depart for Tirthan Valley.</li>
            <li>Visit Tirthan river and Chhoie waterfall.</li>
            <li>Later depart for Delhi. </li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 SWEET HOME</h1>
          <p className="p-4">
            <li>Reach Delhi in early morning.</li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹6500 <span className="">+ 5% GST (Quad Sharing) <br></br>₹7000 + 5% GST (Triple Sharing) <br></br> ₹7500 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img9} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">AC Traveller</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (Breakfast & Dinner)</h2>
            </li>
            <li>
              <h2 className="font-semibold">Cottage & Hotel</h2>
            </li>
            <li>
              <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
            <li>
              <h2 className="font-semibold">All travel permits</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">All tickets and entry pass</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trekone;
