import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdHeight } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/mountabu/1.jpeg";
import img2 from "../images/trips/mountabu/2.jpeg";
import img3 from "../images/trips/mountabu/3.jpeg";
import img4 from "../images/trips/mountabu/4.jpeg";
import img5 from "../images/trips/mountabu/5.jpeg";
import img6 from "../images/trips/mountabu/6.jpeg";
import img7 from "../images/trips/mountabu/7.jpeg";
import img8 from "../images/trips/mountabu/8.jpeg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trek2() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>6 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>1 to 100 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">
           The city of Lakes, Udaipur is a lovely land around the
           azure water lakes, hemmed in by the lush hills of the
           Aravallis. A vision in white drenched in romance and
           beauty, Udaipur is a fascinating blend of sights, sounds
           and experiences - an inspiration for the imagination of
           the poets, painters and writers. Its kaleidoscope of
           fairy-tale palaces, lakes, temples, gardens and narrow
           lanes strewn with stalls, carry the flavor of heroic
           past, epitomizing valor and chivalry. Mount Abu in the
           Sirohi District of Rajasthan is beautiful and one and
           only hill station in Rajasthan. It is one of the most
           popular retreats for vacationers and even honeymoon
           couples. With a distinctive blend of the flavours of
           Rajasthan, Live with no excuses and travel with no
           regrets!!{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 0 DEPART UDAIPUR</h1>
          <p className="p-4">
            <li>Board your Volvo/Train in the evening and leave for Udaipur in the evening.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 ARIVE UDAIPUR</h1>
          <p className="p-4">
            <li>Launch your exclusive Rajasthan Package with a comfortable drive to theenchanting city of Udaipur.</li>
            <li>Upon arrival, check in to your hotel and freshen up for the exciting day ahead.</li>
            <li>On the first day of your Rajasthan package, you will visit some of the major attractions of Rajasthan like the Lake Palace, Jagmandir Island, and Shilpgram. After this, you will get a chance to take in the breathtaking sunset at the lake side. Return to the hotel at night for an overnight stay.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 UDAIPUR SIGHTSEEING</h1>
          <p className="p-4">
            <li>After breakfast, we will go for Badi lake, Fateh Sagar lake, Monsoon
            Palace, Gulabh Bagh sightseeing and local market in the evening.</li>
            <li>Enjoy dinner at the hotel and stay overnight.Enjoy dinner at the hotel and stay overnight.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 ARIVE MOUNT ABU</h1>
          <p className="p-4">
            <li>Explore the beauties of the only hill station Rajasthan.</li>
            <li>After a filling breakfast, checkout from your hotel to embark on the next leg of your Rajasthan holiday package. Enjoy a comfortable drive to Mount abu and enjoy sightseeing of mount abu.</li>
            <li>By the evening start your trip to Hotel.</li>
            <li>Distance b/w Udaipur & Mount Abu: 160 Km & Travel Time: 2 hrs 45 mins.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 LEISURE DAY</h1>
          <p className="p-4">
            <li>After having your breakfast at hotel enjoy your day at your own.</li>
            <li>Explore the Udaipur Markets filled with thousands handcrafted items
            and fabrics of rajasthan like lahariya, bandhej silk and kota dora.</li>
            <li>In the evening departure for delhi and goodbye to the lake city of
            Rajasthan.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 5 SWEET HOME</h1>
          <p className="p-4">
            <li>Early morning arrival in Delhi.</li>
            <li>Cherish Your Lovely Trip Memories for Long! See u again for the next epic Adventure!!</li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹8500 <span className="">+ 5% GST (Quad Sharing) <br></br>₹9000 + 5% GST (Triple Sharing) <br></br> ₹10000 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img4} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">Cab (Udaipur)</h2>
            </li>
            <li>
              <h2 className="font-semibold">3 night stay at 3 Star hotels in Udaipur.</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (3 Breakfast, 3 Dinner)</h2>
            </li>
            <li>
                <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
                <h2 className="font-semibold"> All Travel Permits</h2>
            </li>
            <li>
              <h2 className="font-semibold">Permits</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">Transport</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Insurance</h2>
            </li>
            <li>
                <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
              <h2 className="font-semibold">Any Expenses Not Specifically Mentioned</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trek2;
