import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import { Link } from "react-router-dom";
import logo from "../images/logo.jpg"
function Header() {
  const [toggle, setToggle] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const NavItems = [
    { id: 1, Text: "Home", path: "/" },
    { id: 2, Text: "About", path: "/about" },
    { id: 3, Text: "Contact Us", path: "/Contact" }

  ];

  // Toggle function to handle the navbar's display
  const handleNav = () => {
    setToggle(!toggle);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div className="container w-full  px-10 md:px-[4rem] flex items-center justify-between mx-auto py-5 text-black text-9 z-20 border-b fixed top-0 left-0 bg-white">
      <img className="w-[3.5rem] md:w-[8rem] " src={logo} alt="" />
      <ul className="hidden md:flex gap-10 ">
        {NavItems.map((item) => (
          <li
            key={item.id}
            className=" font-semibold   m-2 cursor-pointer duration-1 w-15  h-7"
          >
            <Link to={item.path} className="hover:text-yellow-500">{item.Text}</Link>
          </li>
        ))}
        <li
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className=" font-semibold  m-2 cursor-pointer duration-1 w-15 h-7"
        >
         <Link  to="/treks"  className="hover:text-yellow-500" > All Tours & Treks</Link>
        </li>
      </ul>
      {/* Mobile Navigation Icon */}
     
      {/* Mobile Navigation Menu */}
      <ul
        className={
          toggle
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-20"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] z-20 "
        }
      >
        {/* Mobile Logo */}
        <h1 className="text-white p-3 text-[26px]">Destinations On Demand</h1>

        {/* Mobile Navigation Items */}
        {NavItems.map((item) => (
          <li
            key={item.id}
            className="p-4 border-b rounded-xl hover:bg-[#ffc941] duration-300 text-white hover:text-white cursor-pointer border-gray-600 "
          >
            <Link to={item.path}>{item.Text}</Link>
          </li>
        ))}
        {/* Mobile All Treks */}
        <li
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="p-4 border-b rounded-xl hover:bg-[#ffc941] duration-300 text-white hover:text-white cursor-pointer border-gray-600 "
        >
          <Link  to="/treks"   > All Tours & Treks</Link>
        </li>
      </ul>
      {/* Mobile Navigation Icon */}
      <div onClick={handleNav} className="block md:hidden">
        {toggle ? <AiOutlineClose size={20} /> : <AiOutlineMenu size={20} />}
      </div>
      {/* Mobile Navigation Menu */}
      <ul
        className={
          toggle
            ? "fixed md:hidden left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#000300] ease-in-out duration-500 z-0"
            : "ease-in-out w-[60%] duration-500 fixed top-0 bottom-0 left-[-100%] "
        }
      >
        {/* Mobile Logo */}
        <h1 className="text-white p-3 text-[26px]">Destinations On Demand</h1>

        {/* Mobile Navigation Items */}
        {NavItems.map((item) => (
          <li
            key={item.id}
            className="p-4 border-b rounded-xl hover:bg-[#ffc941] duration-300 text-white hover:text-black cursor-pointer border-gray-600 "
          >
            <Link to={item.path}>{item.Text}</Link>
          </li>
        ))}
        {/* Mobile All Treks */}
        <li
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          className="p-4 border-b rounded-xl hover:bg-[#ffc941] duration-300 text-black hover:text-white cursor-pointer border-gray-600 "
        ><Link  to="/trekone"  className="hover:text-yellow-500" >
          All Tours & Treks</Link>
        </li>
      </ul>
    </div>
  );
}

export default Header;
