import React from "react";
import { FaInstagram } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa6";
import { FaWhatsapp } from 'react-icons/fa';

import { Link } from "react-router-dom"; 
function Footer() {
  return (
    <>
      <div className="f-te md:flex-row flex flex-col bg-black text-white items-start justify-center gap-11 p-4 md:p-24">
        <div className="conatiner flex flex-col justify-between gap-40">
          <div className="f-links flex flex-col justify-between">
            <div className="f-social flex items-center gap-8">
              <h1 className="text-[36px] font-bold text-yellow-600">
                Follow us
              </h1>
              <div className="icons flex gap-4">
                <Link to="https://www.instagram.com/dod_travels_pvt_ltd?igsh=ZmJwcmJtY2xwNzVp"><FaInstagram /></Link>
                <Link to="https://wa.link/mqrcxc"><FaWhatsapp /></Link>
                <FaFacebookF />
                <FaYoutube />
                <FaTwitter />
              </div>
            </div>
            <ul>
              <Link to="/home"><li className="mt-2">Home</li></Link>
              <Link to="/About"><li className="mt-2">About</li></Link>
              <Link to="/contact"><li className="mt-2">Contact Us</li></Link>
              <Link to="/treks"><li className="mt-2">All Treks</li></Link>
            </ul>
          </div>
          <div className="copyright">
            © 2024 Destinations On Demand Private Limited, <br></br> All images are copyrighted by
            their respective authors.
          </div>
        </div>
        <div className="f-container2">
          <ul>
            <li className="text-[32px] font-bold text-yellow-600 mt-2">Contact us</li>
            <li className="mt-2">+91 9045613081</li>
            <li className="mt-2">Mon to Sat - 10:00 AM to 8:00 PM</li>
            <li className="mt-2">Sun - 10:00 AM to 6:00 PM</li>
          </ul>
          <ul>
            <li className="text-[32px] font-bold text-yellow-600 mt-2"  >New Delhi Office</li>
            <li className="mt-2">
            Plot no. 65A, Ground Floor, Gali no. 4, Laxmi Nagar, East Delhi, 110092, New Delhi, India
            </li>
          </ul>
        </div>
      </div>
    </>
  );
}

export default Footer;
