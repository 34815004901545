import React from 'react'
import Header from '../components/Header'
import Footer from '../components/Footer'
function Contact() {
  return (
    <>
    <Header/>
    <div className="container flex flex-col md:flex md:flex-row  items-center pt-12 justify-center gap-[] md:gap-32 md:pt-[] px-12 mt-[6rem] ">
        <div className="c-te">
          
          <h1 className='text-[36px] font-bold text-yellow-500'>Get in touch</h1>
          <p className='mb-9 text-[20px]'>Ready to embark on your next adventure? Get in touch with us today to start planning your unforgettable journey!</p>
        <p className='mb-9 text-[20px]'> <span className='font-bold'>Email:</span> <a href="mailto:destinationsondemand11@gmail.com">destinationsondemand11@gmail.com</a></p>
        <p className='mb-9 text-[20px]'> <span className='font-bold'>Phone no:</span> <a href="tel:+919045613081">+91 9045613081</a></p>
        <p className='mb-9 text-[20px]'> <span className='font-bold'>Location:</span> Plot no. 65A, Ground Floor, Gali no. 4, Laxmi Nagar, East Delhi, 110092, New Delhi, India</p>
          </div>
          <div className="form w-full h-auto">
           <form action="" method="post" className='flex flex-col border-2 mb-4 border-gray-200 p-10 w-full'>
          <input className='border-2 mb-4 border-gray-200 w-f[35rem] p-2' type="text" placeholder="*Name" />
          <input className='border-2 mb-4 border-gray-200 w-f[35rem] p-2' type="text" placeholder='*Email' />
          <input className='border-2 mb-4 border-gray-200 w-f[35rem] p-2' type="text" placeholder='*Number'/>
          <textarea name="Message" class="resize-none border rounded-md p-2" cols="40" rows="9" id="" placeholder='*Type your message'></textarea>
          <button className="w-[10rem] h-auto font-semibold bg-yellow-500 my-6 py-2 rounded-xl ">
                      Apply now
                    </button>
        </form>
        </div>
    </div>
    <Footer/>
    </>
  )
}

export default Contact