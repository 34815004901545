import React, { useState } from "react";
import mtimg from "../images/mt-img.jpg";
import Header from "../components/Header";
import { FaAnglesDown } from "react-icons/fa6";
import { TbTrekking } from "react-icons/tb";
import { FaClock } from "react-icons/fa6";
import { MdHeight } from "react-icons/md";
import { MdFamilyRestroom } from "react-icons/md";
import img1 from "../images/trips/udaipurmountabu2/1.jpg";
import img2 from "../images/trips/udaipurmountabu2/2.jpg";
import img3 from "../images/trips/udaipurmountabu2/3.jpg";
import img5 from "../images/trips/udaipurmountabu2/4.jpg";
import img6 from "../images/trips/udaipurmountabu2/5.jpg";
import img7 from "../images/trips/udaipurmountabu2/6.jpg";
import img8 from "../images/trips/udaipurmountabu2/7.jpg";
import img9 from "../images/trips/udaipurmountabu2/8.jpg";
import Footer from "../components/Footer";
import { Link } from "react-router-dom"; 
function Trek9() {
  const [showInclusions, setShowInclusions] = useState(true);

  const handleToggle = (section) => {
    if (section === "inclusions") {
      setShowInclusions(true);
    } else {
      setShowInclusions(false);
    }
  };

  return (
    <>
      <Header />
      <img
        src={mtimg}
        alt=""
        className="w-full h-[33rem] block brightness-75"
      />
      <div className="overlay-text absolute left-[50%] top-[30%] md:top-[50%] transform -translate-x-1/2 -translate-y-1/2 text-white">
        <h1 className="text-[65px] font-bold">About Trips & Treks</h1>
        <p className="text-[18px] font-bold">
          A summit climb to one of India's best panoramas
        </p>
      </div>
      <a href="#photo-gallery">
        <button className="absolute left-[65%] top-[50%] md:left-[85%] md:top-[65%] bg-yellow-50 font-semibold p-2 flex gap-2 items-center justify-center hover:bg-yellow-400 w-[8rem] rounded-[1.5rem]">
          Photos
          <FaAnglesDown className="icon" />
        </button>
      </a>
      <div className="date-section bg-black text-white p-4">
        <p>
          Wanna know the dates? <a href="https://wa.link/mqrcxc">Date</a>
        </p>
      </div>
      <section className="trek-info flex items-center justify-evenly my-[4rem]">
        <div className="flex flex-col md:flex md:flex-row gap-1 md:gap-2 md:px-[]  px-3">
          <TbTrekking className="md:text-[3rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DIFFICULTY</h1>
            <p>Easy Moderate</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <FaClock className="md:text-[2.5rem] text-[1rem] text-green-600" />
          <div>
            <h1 className="font-bold">TREK DURATION</h1>
            <p>5 days</p>
          </div>
        </div>
        <div className="flex flex-col md:flex md:flex-row gap-2">
          <MdFamilyRestroom className="md:text-[3rem] text[6rem] text-green-600" />
          <div>
            <h1 className="font-bold text-[12px]">SUITABLE FOR</h1>
            <p>10 to 60 years</p>
          </div>
        </div>
      </section>
      <section className="trek-about flex-col md:flex md:flex-row justify-start gap-12 px-1 md:px-28">
        <div>
          <h1 className="text-[36px] font-bold px-4">
            Overview:-
          </h1>
          <p className="p-4">
          Udaipur, formerly the capital of the Mewar Kingdom, is a city in the western Indian state of Rajasthan. Founded by Maharana Udai Singh II in 1559, it's set around a series of artificial lakes and is known for its lavish royal residences. City Palace, overlooking Lake Pichola, is a monumental complex of 11 palaces, courtyards and gardens, famed for its intricate peacock mosaics.{" "}
          </p>
          <h1 className="text-[36px] font-bold px-4">Pickup & Drop:-</h1>
          <p className="p-4">Delhi to Delhi</p>
          <h1 className="text-[36px] font-bold px-4"> Day:- 0</h1>
          <p className="p-4"> <li>Pickup From Delhi</li></p>
          <h1 className="text-[36px] font-bold px-4">Day:- 1 REACH UDAIPUR</h1>
          <p className="p-4">
            <li>Reach Udaipur in morning/afternoon, check into your hotel and freshen up.</li>
            <li>Later Visit Lake Pichola, City Palace, Jagdish Temple, Bagore Ki Haveli.</li>
            <li>Return back to hotel.</li>
            <li>Dinner overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 2 MOUNT ABU, BADI LAKE</h1>
          <p className="p-4">
            <li>Wake up early in the morning, freshen up and have your breakfast.</li>
            <li>Later depart for Mount Abu. Along the route, we will visit Badi lake.</li>
            <li>Reach Mount Abu in afternoon.</li>
            <li>Later visit Diwara Jain Temple, Nakki Lake, Brahamkumari Ashram, Sunset Point etc.</li>
            <li>Return back to hotel in Udaipur by evening. </li>
            <li>Dinner & overnight stay in hotel.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 3 CHITTORGARH </h1>
          <p className="p-4">
            <li>Wake up in morning, freshen up and have breakfast, then check out from hotel.</li>
            <li>Later visit Sheliyo Ki Bari, Vintage Car Museum etc.</li>
            <li>If time permits, we'll also visit Chittorgarh Fort / Nathdwara Temple.</li>
            <li>Afterward, start your return journey to Delhi.</li>
          </p>
          <h1 className="text-[36px] font-bold px-4">Day:- 4 SWEET HOME</h1>
          <p className="p-4">
            <li>Sweet Home </li>
          </p>
        </div>
        <div>
          <div className="card-payment w-screen md:w-[30rem] border-[1rem] border-yellow-600 p-[1rem] h-[20rem] flex flex-col gap-[1rem]">
            <h1 className="text-[36px] font-bold">Trek Fee</h1>
            <h1 className="text-yellow-600">
              ₹6500 <span className="">+ 5% GST (Quad Sharing) <br></br>₹7000 + 5% GST (Triple Sharing) <br></br> ₹8500 + 5% GST (Duo Sharing) </span>
            </h1>
            <button style={{ fontWeight: 'bold' }}> Let's travel together!</button>
            <div className="dates-availibility">
              <h1 style={{ fontWeight: 'bold' }}>Click here to join the trip:-</h1>
              <Link to="https://wa.link/mqrcxc"><div className="bg-yellow-100" style={{ fontWeight: 'bold' }}>Apply</div></Link>
            </div>
          </div>
        </div>
      </section>
      <section
        className="photo-gallery mt-24 flex flex-col items-center justify-center"
        id="photo-gallery"
      >
        <div className="heading mb-9">
          <h1 className="text-[36px] text-center font-bold">Photo Gallery</h1>
          <hr className="w-full md:w-[40rem] mt-8 h-1 bg-yellow-400" />
        </div>
        <div className="gallery-photos flex flex-col gap-5">
          <div className="flex-col  md:flex md:flex-row  md:gap-5">
            <img className="w-[20rem] mt-4" src={img1} alt="" />
            <img className="w-[20rem] mt-4" src={img2} alt="" />
            <img className="w-[20rem] mt-4" src={img3} alt="" />
            <img className="w-[20rem] mt-4" src={img9} alt="" />
          </div>
          <div className="flex-col md:flex md:flex-row gap-5">
            <img className="w-[20rem] mt-4" src={img5} alt="" />
            <img className="w-[20rem] mt-4" src={img6} alt="" />
            <img className="w-[20rem] mt-4" src={img7} alt="" />
            <img className="w-[20rem] mt-4" src={img8} alt="" />
          </div>
        </div>
      </section>
      <section className="inclusion-exclusion my-12 px-[1rem] md:px-28">
        <div className="heading mb-4">
          <h1 className="text-[36px] font-bold">
            See what's Included and Excluded in the Trek Fee:-
          </h1>
        </div>
        <div className="inclusion-display-toggle flex gap-4 mb-4">
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("inclusions")}
          >
            Inclusion
          </button>
          <button
            className={`bg-yellow-50 font-semibold p-2 hover:bg-yellow-400 rounded-lg ${
              !showInclusions ? "bg-yellow-400" : ""
            }`}
            onClick={() => handleToggle("exclusions")}
          >
            Exclusion
          </button>
        </div>
        <div className={showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Inclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">AC Traveller</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals (Breakfast & Dinner)</h2>
            </li>
            <li>
              <h2 className="font-semibold">Cottage & Hotel</h2>
            </li>
            <li>
              <h2 className="font-semibold">Tour Cordinator</h2>
            </li>
            <li>
              <h2 className="font-semibold">Medical Kit</h2>
            </li>
            <li>
              <h2 className="font-semibold">All travel permits</h2>
            </li>
          </ul>
        </div>
        <div className={!showInclusions ? "" : "hidden"}>
          <h1 className="text-[24px] font-bold mb-2">Exclusions in the Trek Fee:-</h1>
          <ul className="list-disc pl-5 space-y-4">
            <li>
              <h2 className="font-semibold">All tickets and entry pass</h2>
            </li>
            <li>
              <h2 className="font-semibold">Personal Expenses</h2>
            </li>
            <li>
              <h2 className="font-semibold">Meals durning travelling time & Lunch</h2>
            </li>
            <li>
                <h2 className="font-semibold">Any other costing involved due to any kind of natural calamity, forced circumstances which are out of our control.</h2>
            </li>
          </ul>
        </div>
      </section>
      <Footer/>
    </>
  );
}

export default Trek9;
